import axios from 'axios';
import { EVA_URL } from '../helpers/urlHelper';
import { clearStore, getItemFromStore, setItemToStore } from 'helpers/utils';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
axios.defaults.withCredentials = true;
const userKey = 'eva.users.internal';

const parseUserToDropdownValue = async (strUserId) => {
  const controller = new AbortController();
  const users = await getAllEvaUsers(controller.signal);
  var values = splitStringMapValueToDropDown(strUserId, users)
  return values;
}

const getAllEvaUsers = async signal => {
  const cache = getItemFromStore(userKey, [], sessionStorage);
  if (cache && cache.length) return cache;

  let users = await axios({
    method: 'get',
    url: `${EVA_URL}/users/internal`,
    signal: signal
  })
    .then(res => {
      if (res.status !== 200) return [];

      return res.data.map(x => {
        return { ...x, value: x.userId, label: x.name };
      });
    })
    .catch(err => {
      console.log(err);
      return [];
    });

  setItemToStore(userKey, JSON.stringify(users), sessionStorage);
  return users;
};

const signOut = async () => {
  let res = await axios({
    method: 'post',
    url: `${EVA_URL}/auth/logout`
  });

  console.log(res);

  clearStore(sessionStorage);

  return;
};

const getUserById = async id => {
  let storeUser = getItemFromStore('user', {}, sessionStorage);

  if (storeUser && storeUser.userId && storeUser.userName) return storeUser;

  let user = await axios({
    method: 'get',
    url: `${EVA_URL}/users/${id}`
  })
    .then(res => {
      if (res.status === 200) return res.data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });

  setItemToStore(
    'user',
    JSON.stringify({ ...storeUser, ...user }),
    sessionStorage
  );
  return user;
};

export { getAllEvaUsers, getUserById, signOut, parseUserToDropdownValue };
