import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import { postMeeting } from 'services/customer-service/scMeetingService';
import UserDropdownV2 from 'components/Dropdowns/CustomerServices/UserDropdownV2';
import { RRule } from 'rrule';
import moment from 'moment';
import { RecurrenceModal, weekday } from './RecurrenceModal';

const AddMeetingModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate,
  handleSubmit
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [formData, setFormData] = useState({});
  const [showRecurrence, setShowRecurrence] = useState(false);

  const utcStart = moment.utc(Date.now());
  const wDay = weekday.find(i => i.id.weekday == utcStart.day());
  const byweekday = wDay ? [wDay.id] : undefined;
  const [rRule, setRRule] = useState({
    freq: RRule.WEEKLY,
    dtstart: new Date(
      Date.UTC(
        utcStart.year(),
        utcStart.month(),
        utcStart.date(),
        utcStart.hour(),
        utcStart.minute(),
        utcStart.second()
      )
    ),
    byweekday: byweekday
  });

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };

  const handleDropdown = (field, value) => {
    setFormData({ ...formData, [field]: value.map(i => i.userId) });
  };

  const handleChange = ({ target }) => {
    console.log(target);
    console.log(formData);
    let name = target.name;
    let value = name === 'allDay' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async e => {
    console.log('submit');
    console.log(formData);
    e.preventDefault();
    var repeatRule = undefined;
    if (showRecurrence) {
      repeatRule = { ...rRule };
      repeatRule.byweekday = rRule.byweekday?.map(i => i.weekday);
    }

    await postMeeting({ id: uuid(), ...formData, repeatRule });
    handleSubmit();
    setIsOpenScheduleModal(false);
  };

  useEffect(() => {
    if (isOpenScheduleModal) {
      setFormData({
        ...formData,
        allDay: false,
        start: scheduleStartDate,
        end: scheduleEndDate,
        startStr: scheduleStartDate?.toISOString(),
        endStr: scheduleEndDate?.toISOString()
      });

      

      const utcScheduleStart = moment.utc(scheduleStartDate?.toISOString());
      const wd = weekday.find(i => i.id.weekday == utcScheduleStart.day());
      const byweekday = wd ? [wd.id] : undefined;
      setRRule({
        freq: RRule.WEEKLY,
        dtstart: new Date(
          Date.UTC(
            utcScheduleStart.year(),
            utcScheduleStart.month(),
            utcScheduleStart.date(),
            utcScheduleStart.hour(),
            utcScheduleStart.minute(),
            utcScheduleStart.second()
          )
        ),
        byweekday: byweekday
      });
    } else {
      setScheduleStartDate(null);
      setScheduleEndDate(null);
    }
  }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

  return (
    <Modal
      size="lg"
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Create Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0 required">Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              required
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label className="fs-0">Start Date</Form.Label>
            <DatePicker
              calendarStartDay={1}
              selected={scheduleStartDate}
              onChange={date => {
                const utcStrTime = date ? date.toISOString() : '';
                setFormData({ ...formData, startStr: utcStrTime, start: date });
                setScheduleStartDate(date);
              }}
              className="form-control"
              placeholderText="dd-MM-yyyy H:M"
              dateFormat="dd-MM-yyyy h:mm aa"
              showTimeSelect
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="endDate">
            <Form.Label className="fs-0">End Date</Form.Label>
            <DatePicker
              calendarStartDay={1}
              selected={scheduleEndDate}
              onChange={date => {
                setScheduleEndDate(date);
                const utcStrTime = date ? date.toISOString() : '';
                setFormData({ ...formData, endStr: utcStrTime, end: date });
              }}
              className="form-control"
              placeholderText="dd-MM-yyyy H:M"
              dateFormat="dd-MM-yyyy h:mm aa"
              showTimeSelect
            />
          </Form.Group>
          <Form.Group controlId="allDay">
            <Form.Check
              type="checkbox"
              id="allDay"
              label="All Day"
              name="allDay"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="frequency">
            <Form.Check
              type="checkbox"
              id="repeat"
              label="Repeat"
              name="repeat"
              checked={showRecurrence}
              onChange={() => {
                setShowRecurrence(!showRecurrence);
              }}
            />
          </Form.Group>

          {showRecurrence && (
            <RecurrenceModal
              isShow={showRecurrence}
              setRRule={r => {
                console.log(r);
                setRRule(r);
              }}
              rRuleParam={rRule}
              rRule={rRule}
              setScheduleStartDate={setScheduleStartDate}
            />
          )}

          <Form.Group className="mb-3" controlId="meetingLink">
            <Form.Label className="fs-0">Meeting Link</Form.Label>
            <Form.Control
              type="text"
              name="meetingLink"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="venue">
            <Form.Label className="fs-0">Venue</Form.Label>
            <Form.Control
              type="text"
              name="venue"
              value={formData?.venue ? formData.venue : ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Col md="12">
            <UserDropdownV2
              label="Participants persons"
              fieldName="participants"
              handleChange={handleDropdown}
              placeholder="Participants persons"
            />
          </Col>
          <Col md="12">
            <UserDropdownV2
              label="Managed By"
              fieldName="managedBy"
              handleChange={handleDropdown}
              placeholder="Managed By"
            />
          </Col>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label className="fs-0">Objective</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Link to="#!" className="me-3 text-600">
            More options
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={onsubmit}
            className="px-4 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AddMeetingModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array,
  handleSubmit: PropTypes.func,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired
};

export default AddMeetingModal;
