import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import CustomersFocusTableHeader from './CustomersFocusTableHeader';
import { CustomersFocusTable } from './CustomersFocusTable';
import { joinDataMultiDropdown } from 'helpers/dropdownFuncHelper';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { searchCustomersFocus } from 'services/meeting-management-services/mmCustomersFocusService';
import CustomersFocusFilter from './CustomersFocusFilter';
import PropTypes from 'prop-types';
import {
  KEY_CF_MEETING_CONFIG
} from 'services/base-services/userSettingServices';
import { usePaginationBe, useHanleConfig } from 'hooks/useBeHandle';
const RunningCustomersFocus = ({ meetingId, showWhenNoData = true, pageSize=10 }) => {
  const [filter, setFilter] = useState({ progresses: '1,2' });
  const defaultRQ = {
    ...new BaseTableRQ(1, pageSize),
    ...filter,
    meetingId: meetingId
  };
  const [actionPlans, setCustomersFocuss] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultRQ, meetingId: meetingId });
  const [isFilter, setIsFilter] = useState(false);
  const [reload, setReload] = useState(false);
  const [colSort, setColSort] = useState(undefined);

  const loadCustomersFocuss = pagingRq => {
    searchCustomersFocus(pagingRq)
      .then(res => {
        if (res) {
          setCustomersFocuss(res.items);
          setTotal(res.total);
          let cl = {
            ...pagingRq,
            sortBy: pagingRq.sortBy,
            desc: pagingRq.desc,
            isBeSortedDesc: pagingRq.desc,
            isBeSorted: pagingRq.sortBy != undefined && pagingRq.sortBy != ''
          };
          setColSort(cl);
          setPaging(cl);
          setFilter(cl);
        }
      })
      .catch(err => {
        console.log(err);
      });
    setPaging(pagingRq);
  };

  useEffect(() => {
    setIsFilter(false);
    getConfig();
  }, []);

  const defaultPaging = data => {
    return {
      ...data,
      pageNum: defaultRQ.pageNum,
      pageSize: defaultRQ.pageSize,
      isLike: defaultRQ.isLike,
      isGetAll: defaultRQ.isGetAll
    };
  };

  const toggelFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleChange = (field, value) => {
    console.log(field, value);
    setFilter({
      ...filter,
      [field]: value
    });
  };

  const handleSubmit = () => {
    let rq = mapRQData(filter);
    loadCustomersFocuss({ ...defaultRQ, ...rq });
    setReload(!reload);
  };

  const mapRQData = data => {
    if (data) {
      let mapRq = { ...data };
      if (data?.targetDate && data?.targetDate instanceof Date) {
        mapRq.targetDate = data?.targetDate?.toISOString();
      }

      mapRq.progresses = joinDataMultiDropdown(data?.progresses);
      mapRq.reporters = joinDataMultiDropdown(data?.reporters);
      mapRq.priorities = joinDataMultiDropdown(data?.priorities);
      mapRq.assignees = joinDataMultiDropdown(data?.assignees);
      mapRq.requestParticipants = joinDataMultiDropdown(
        data?.requestParticipants
      );
      mapRq.departments = joinDataMultiDropdown(data?.departments);
      return mapRq;
    }
    return null;
  };

  const { gotoPage, nextPage, previousPage, handleSort } = usePaginationBe(
    paging,
    total,
    loadCustomersFocuss,
    filter,
    setFilter
  );
  const { getConfig, saveConfig } = useHanleConfig(
    KEY_CF_MEETING_CONFIG,
    filter,
    mapRQData,
    defaultRQ,
    defaultPaging,
    loadCustomersFocuss
  );

  return (
    <>
      {(actionPlans.length > 0 || showWhenNoData) && (
        <Card>
          <Row style={{ padding: '10px' }}>
            <CustomersFocusTableHeader
              toggelFilter={() => toggelFilter()}
              meetingId={meetingId}
              style={{ padding: '10px' }}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isFilter={isFilter}
              setReload={setReload}
              keyword={filter?.keyword}
              reload={reload}
            />
            {isFilter && (
              <Col
                lg={12}
                style={{ paddingBottom: '10px', paddingTop: '10px' }}
              >
                <CustomersFocusFilter
                  displayOption={{
                    showHeader: true,
                    showFooter: true,
                    col: '3'
                  }}
                  style={{
                    paddingBottom: '10px',
                    maxHeight: '250px',
                    overflowY: 'auto'
                  }}
                  handleChange={handleChange}
                  formData={filter}
                  handleSubmit={handleSubmit}
                  saveConfig={saveConfig}
                />
              </Col>
            )}
            <Col lg={12}>
              <CustomersFocusTable
                data={actionPlans}
                paging={paging}
                total={total}
                nextPage={nextPage}
                previousPage={previousPage}
                gotoPage={gotoPage}
                reload={reload}
                colSort={colSort}
                handleSort={handleSort}
              />
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

RunningCustomersFocus.propTypes = {
  meetingId: PropTypes.string,
  pageSize: PropTypes.number,
  isFullRow: PropTypes.bool,
  showWhenNoData: PropTypes.bool
};

export default RunningCustomersFocus;

const CardNumberWidget = ({ title, data }) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="flex-between-center g-0 h-100 align-items-center justify-content-center text-center">
          <Col>
            <h6 className="mb-2 text-900">{title}</h6>
            <h4 className="fs-3 fw-normal text-700 mb-0">{data}</h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CardNumberWidget.propTypes = {
  meetingId: PropTypes.number,
  pageSize: PropTypes.number,
  isFullRow: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.string
};
