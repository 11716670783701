import React, { useEffect, useState } from 'react';
import { Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
// import EVADropDown from '../../../Dropdowns/OptionListSelect';
import PropTypes from 'prop-types';
import MultiSelect from 'components/common/MultiSelect';
import {
  getDepartmentsSelected,
  saveDepartmentsSelected
} from 'services/newCritical';
import { getItemFromStore } from 'helpers/utils';

const Header = ({
  filter,
  setFilters,
  search,
  depts,
  setDepartmentsSelected
}) => {
  const { dateFrom, dateTo, keyword } = filter;
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const buildOption = data => {
    return data.map(item => {
      return {
        value: item.id,
        label: item.name
      };
    });
  };

  useEffect(() => {
    if (depts.length > 0) {
      const dataOptions = buildOption(depts);
      setOptions(dataOptions);
    }
  }, [depts]);

  useEffect(() => {
    const currentUser = getItemFromStore('user', {}, sessionStorage);
    if (
      currentUser &&
      currentUser.isAuthenticated &&
      depts &&
      depts.length > 0
    ) {
      getDepartmentsSelected(currentUser.userId)
        .then(res => {
          if (res) {
            const lstIds = res.orderStage.split(',').map(id => +id);
            if (lstIds.length > 0) {
              const deptsSelected = depts.filter(d => lstIds.includes(d.id));
              const sortedDeptsSelected = deptsSelected.sort(
                (a, b) => a.value2 - b.value2
              );
              const values = buildOption(sortedDeptsSelected);
              setSelected(values);
              setDepartmentsSelected(sortedDeptsSelected);
            }
          } else {
            setSelected([]);
            setDepartmentsSelected(depts);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);

  const handleChangeDepts = value => {
    const selected = [];
    let lstIds = '';
    if (value.length > 0) {
      value.map((v, idx) => {
        const match = depts.filter(d => d.id === v.value);
        selected.push(...match);
        if (idx === value.length - 1) {
          lstIds += v.value;
        } else {
          lstIds += v.value + ',';
        }
      });
      setDepartmentsSelected(selected.sort((a, b) => a.value2 - b.value2));
    } else {
      setDepartmentsSelected(depts);
    }

    const currentUser = getItemFromStore('user', {}, sessionStorage);
    if (currentUser && currentUser.isAuthenticated) {
      const payload = {
        userId: currentUser.userId,
        orderStage: lstIds,
        enabled: true
      };
      saveDepartmentsSelected(payload).catch(error => {
        console.log(error);
      });
    }
    setSelected(value);
  };

  return (
    <Row className="gx-1 kanban-header rounded-2 px-card pt-2 pb-4 mt-2 mb-3 d-flex align-items-end">
      <Col xs={12} md={5} lg={3} className="mx-1 my-2 my-lg-0">
        <Form.Control
          placeholder="Search by customer code, name or ref..."
          value={keyword}
          onChange={e => setFilters({ ...filter, keyword: e.target.value })}
        ></Form.Control>
      </Col>
      <Col xs={12} md={10} lg={4} className="mx-1">
        <Form.Label sm={1}>Delivery Date</Form.Label>
        <Col>
          <InputGroup>
            <Col xs={5}>
              <DatePicker
                calendarStartDay={1}
                id="date-from"
                className="form-control"
                placeholderText="Select From"
                onChange={date =>
                  setFilters({
                    ...filter,
                    dateFrom: new Date(date).toISOString()
                  })
                }
                dateFormat={'dd/MM/yyyy'}
                selected={new Date(dateFrom)}
              />
            </Col>
            <Col xs={2}>
              <InputGroup.Text>To</InputGroup.Text>
            </Col>
            <Col xs={5}>
              <DatePicker
                calendarStartDay={1}
                id="date-to"
                className="form-control"
                placeholderText="Select To"
                onChange={date =>
                  setFilters({
                    ...filter,
                    dateTo: new Date(date).toISOString()
                  })
                }
                dateFormat={'dd/MM/yyyy'}
                selected={new Date(dateTo)}
              />
            </Col>
          </InputGroup>
        </Col>
      </Col>
      <Col xs={12} md={5} lg={3} className="mx-1">
        <Form.Label sm={1}>Departments</Form.Label>
        <MultiSelect
          options={options}
          isMulti={true}
          value={selected}
          onChange={handleChangeDepts}
        ></MultiSelect>
      </Col>
      <Col xs={1} className="mx-1">
        <Button
          variant="primary"
          className="ms-0 me-0 mb-1 my-2 my-lg-0 float-lg-end"
          onClick={async () => await search(filter)}
        >
          Search
        </Button>
      </Col>
    </Row>
  );
};

Header.propTypes = {
  filter: PropTypes.object,
  setFilters: PropTypes.func,
  search: PropTypes.func,
  depts: PropTypes.array,
  setDepartmentsSelected: PropTypes.func
};

export default Header;
