import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import MeetingDetailHeader from './MeetingDetailHeader';
import { useParams } from 'react-router-dom';
import { getMeeting } from 'services/customer-service/scMeetingService';
import moment from 'moment';
import UserDropdownV2 from 'components/Dropdowns/CustomerServices/UserDropdownV2';
import RunningActionPlans from '../../action-plans/action-plan-dashboard/RunningActionPlans';
import { patchRelatedMeetingFields } from 'services/customer-service/scMeetingService';
import CustomersFocusWidget from 'components/pages/meeting-management/customers-focus-dashboard/CustomersFocusWidget';
const MeetingDetail = () => {
  const [detail, setDetail] = useState({});
  const { id } = useParams();
  const fetchData = async id => {
    await getMeeting(id).then(res => {
      if (res?.data && res?.data.id > 0) {
        const content = res?.data;
        const data = {
          title: content.title,
          start: new Date(
            moment.utc(content.start).local().format('YYYY-MM-DD HH:mm:ss')
          ),
          end: content.end
            ? new Date(
                moment.utc(content.end).local().format('YYYY-MM-DD HH:mm:ss')
              )
            : '',
          id: content.id,
          allDay: content.allDay,
          meetingLink: content.meetingLink,
          description: content.description,
          participants: content.participants,
          createdBy: content.createdBy
        };

        setDetail(data);
      }
    });
  };
  useEffect(() => {
    fetchData(id);
  }, []);

  const handleChange = (field, value) => {
    console.log(field, value);

    patchRelatedMeetingFields(id, {
      [field]: value.map(i => i.userId)
    }).then(() => {
      fetchData(id);
    });
  };

  return (
    <>
      <MeetingDetailHeader
        title={detail.title}
        author={detail.createdBy}
        eventDate={{
          day: moment(detail.start).format('DD'),
          month: moment(detail.start).format('MMM')
        }}
        regFee={``}
      />
      <Row className="g-3">
        <Col lg={8}>
          <Card>
            <Card.Body>
              <h5 className="fs-0 mb-3">{detail.title}</h5>
              <p>{detail.description}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <Card className="mb-3 fs--1">
              <Card.Body>
                <Col md="12">
                  <h6>Date And Time</h6>
                  <p className="mb-1">
                    From :{' '}
                    {moment(detail.start)
                      .format('ddd DD MMM YYYY HH:mm')
                      .toString()}
                    <br />
                    {detail.end ? ' - ' : ''} <br />
                    To :{' '}
                    {detail.end
                      ? moment(detail.end)
                          .format('ddd DD MMM YYYY HH:mm')
                          .toString()
                      : ''}
                  </p>
                  <br />
                </Col>
                <Col md="12">
                  <UserDropdownV2
                    label="Participants persons"
                    fieldName="participants"
                    placeholder="Participants persons"
                    value={detail?.participants}
                    handleChange={handleChange}
                  />
                </Col>
              </Card.Body>
            </Card>
            {/* <Events cardTitle="Events you may like" events={events.slice(2, 5)} /> */}
          </div>
        </Col>
      </Row>
      {(id == 5 || id == 1) && detail?.id > 0 && <CustomersFocusWidget details={detail}/>}
      <Row className="g-3">
        <Col lg={12}>
          <RunningActionPlans meetingId={id} />
        </Col>
      </Row>
    </>
  );
};

export default MeetingDetail;
