import axios from 'axios';
import { EVA_URL } from '../../helpers/urlHelper';

axios.defaults.withCredentials = true;

const baseURL = `${EVA_URL}/customer-focus-summary`;

const getNNCCustomers = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/get-nc-customers`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      return {
        data: response.data.data,
        url: 'https://eva.emplas.co.uk/Internal/Reports/MeetingReport.aspx'
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};
const getThisWeekNewCCFs = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/get-this-week-new-ccfs`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const newCffsURL =
      process.env.REACT_APP_EVA_URL +
      '/Internal/CCF/CCFSearch.aspx?mode=newccfs';
    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      return {
        data: response.data.data,
        url: `${newCffsURL}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};
const getNewCCFs = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/get-new-ccfs`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const newCffsURL =
      process.env.REACT_APP_EVA_URL +
      '/Internal/CCF/CCFSearch.aspx?mode=newccfs';
    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      return {
        data: response.data.data,
        url: `${newCffsURL}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};
const getNNCNewCCFs = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/get-nc-new-ccfs`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const newCffsURL =
      process.env.REACT_APP_EVA_URL +
      '/Internal/CCF/CCFSearch.aspx?mode=newccfs';
    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      return {
        data: response.data.data,
        url: `${newCffsURL}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};
const getMissingFeedbackRequiredInfoCCFs = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/get-info-feedback-ccfs`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const newCffsURL =
      process.env.REACT_APP_EVA_URL +
      '/Internal/CCF/CCFSearch.aspx?mode=missing-feedbackrequired-info';
    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      return {
        data: response.data.data,
        url: `${newCffsURL}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
}
const getThisWeekCommercialDDC = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/get-this-week-commercial-ddc`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const newCffsURL =
      process.env.REACT_APP_EVA_URL +
      '/Internal/CCF/CCFSearch.aspx?mode=newccfs';
    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      return {
        data: response.data.data,
        url: `${newCffsURL}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};
const getThisWeekTradeDDC = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/get-this-week-trade-ddc`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const newCffsURL =
      process.env.REACT_APP_EVA_URL +
      '/Internal/CCF/CCFSearch.aspx?mode=newccfs';
    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      return {
        data: response.data.data,
        url: `${newCffsURL}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

export {
  getNNCCustomers,
  getThisWeekNewCCFs,
  getNewCCFs,
  getNNCNewCCFs,
  getMissingFeedbackRequiredInfoCCFs,
  getThisWeekTradeDDC,
  getThisWeekCommercialDDC
};
