import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Authentication from 'components/pages/npd/landing/Authentication';
import { getItemFromStore } from 'helpers/utils';
import { initGoogleAnalytics, setUserId } from 'helpers/googleAnalytics';

export default function ProtectedRoute() {
  const authFromRedux = useSelector(state => state.user.isAuthenticated);
  const authFromStorage = getItemFromStore(
    'user',
    {},
    sessionStorage
  ).isAuthenticated;
  const userId = getItemFromStore('user', {}, sessionStorage).userId;

  useEffect(() => {
    if (authFromRedux || authFromStorage) {
      initGoogleAnalytics();

      if (userId) {
        console.log(userId)
        setUserId(userId);
      }
    }
  }, [authFromRedux, authFromStorage, userId]);

  return authFromRedux || authFromStorage ? <Outlet /> : <Authentication />;
}
