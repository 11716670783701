import { parseUserToDropdownValue } from 'services/userServices';
import { EVA_URL } from '../../helpers/urlHelper';
import moment from 'moment';
import {
  previewDocument,
  buildTableXml,
  escapeXml,
  getBlob
} from 'helpers/wordExportHelper';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
import { progresses } from 'data/TempData';
import { getAllEvaUsers } from 'services/userServices';
import { swalToastError, swalToastSuccess } from 'components/utilities/SwalToastUtil';

const buildParamOfAttachment = async data => {
  const ccfHeaders = [
    { headerText: 'Department', value: 'department' },
    { headerText: 'Quantity by Dept', value: 'quantityByDept' },
    { headerText: 'Feedback/Actions', value: 'comments' }
  ];

  const ccfRows = data.faultSource.map(x => {
    let count = data.newCCFs.data.filter(a => a.faultSource == x.id).length;
    return {
      department: x.name,
      atRisk: 0,
      supplierDateChanges: 0,
      quantityByDept: count,
      comments: ''
    };
  });

  ccfRows.push({
    department: 'Total',
    atRisk: 0,
    supplierDateChanges: 0,
    quantityByDept: ccfRows.reduce(
      (total, item) => total + item.quantityByDept,
      0
    ),
    comments: ''
  });

  const ccfByDeptResultsXml = buildTableXml(ccfHeaders, ccfRows);
  const nNCOdrCcfDdscHeaders = [
    { headerText: 'Department', value: 'department' },
    { headerText: 'ODRS Aged New', value: 'odrsAgedNew' },
    { headerText: 'CCFS', value: 'ccfs' },
    { headerText: 'DDCs', value: 'ddcs' },
    { headerText: 'Dept Head Comments', value: 'comments' }
  ];
  // nncCCFs,
  // nncOdrs

  console.log(data)
  const nNCOdrCcfDdscRows = data.faultSource.map(x => {
    let ccfCount = data.nncCCFs.data.filter(a => a.faultSource == x.id).length;
    let odrCount = data.nncOdrs.data.filter(a => a.faultSource == x.id).length;
    return {
      department: x.name,
      odrsAgedNew: odrCount,
      ccfs: ccfCount,
      ddcs: 0,
      comments: '',
    };
  });

  nNCOdrCcfDdscRows.push({
    department: 'Total',
    odrsAgedNew: nNCOdrCcfDdscRows.reduce(
      (total, item) => total + item.odrsAgedNew,
      0
    ),
    ccfs: nNCOdrCcfDdscRows.reduce(
      (total, item) => total + item.ccfs,
      0
    ),
    ddcs: 0,
    comments: ''
  });

  const nNCOdrCcfDdscXml = buildTableXml(
    nNCOdrCcfDdscHeaders,
    nNCOdrCcfDdscRows
  );

  const actionPlanHeaders = [
    { headerText: 'Action Number', value: 'id' },
    { headerText: 'Description of Action', value: 'description' },
    { headerText: 'Date added', value: 'created' },
    { headerText: 'Assigned to', value: 'requestParticipants' },
    { headerText: 'Target Date for completion', value: 'targetDate' },
    {
      headerText: 'Customer Communication Status',
      value: 'customerCommStatus'
    },
    { headerText: 'Progress Status', value: 'progress' },
    { headerText: 'Additional Comments', value: 'additionalComments' },
    { headerText: 'Feedback Generated', value: 'feedbackGenerated' }
  ];

  const users = await getAllEvaUsers();

  const actionPlanXml = buildTableXml(
    actionPlanHeaders,
    await Promise.all(
      data.actionPlans.map(async plan => {
        const participants = splitStringMapValueToDropDown(
          plan.requestParticipants,
          users
        )
          .map(user => user.name)
          .join(', ');
        const status = splitStringMapValueToDropDown(
          plan.progress.toString(),
          progresses
        )
          .map(x => x.label)
          .join(', ');
        const commentText = data.comments
          .filter(x => x.actionPlanId == plan.id)
          .map(x => {
            const text = parseHtmlToText(x.description);
            console.log(users);
            const userName = users.find(u => u.userId == x.createdBy).name;

            return userName + ' : ' + text;
          })
          .join(', \n\r');
        return {
          ...plan,
          created: moment(plan.created).format('DD/MM/YYYY'),
          targetDate: moment(plan.targetDate).format('DD/MM/YYYY'),
          description: parseHtmlToText(plan.description),
          requestParticipants: participants,
          progress: status,
          additionalComments: commentText
        };
      })
    )
  );

  const concernHeaders = [
    { headerText: 'Action Number', value: 'actionNo' },
    { headerText: 'Concern', value: 'concern' },
    { headerText: 'Date added', value: 'dateAdded' },
    { headerText: 'Assigned to', value: 'assignedTo' },
    {
      headerText: 'Description of containment',
      value: 'descriptionOfContainment'
    },
    { headerText: 'Date of implementation', value: 'dateOfImplementation' },
    {
      headerText: 'Corrective/Preventative Action',
      value: 'correctivePreventativeAction'
    },
    { headerText: 'Customer Com Status', value: 'customerCommStatus' },
    { headerText: 'Progress Status', value: 'progressStatus' },
    { headerText: 'Additional Comments', value: 'additionalComments' }
  ];

  const concernRows = [];

  const concernXml = buildTableXml(concernHeaders, concernRows);

  let attendees = (await parseUserToDropdownValue(data.participants))
    .map(participant => participant.name.trim())
    .join('\n');
  console.log(data);
  const param = {
    date: moment().format('DD/MM/YYYY'),
    attendees,
    agedODRXml: buildAgedODR(
      data.agedODRs.data,
      data.faultSource,
      data.agedAtRiskODRs.data,
      data.supplierDeliveryChangesAgedODRs.data
    ),
    newODRsXml: buildNewODR(
      data.newODRs.data,
      data.faultSource,
      data.newAtRiskODRs.data,
      data.supplierDeliveryChangesNewODRs.data
    ),
    ccfByDeptResultsXml: ccfByDeptResultsXml,
    newNCritialCustomersXml: buildNNCCustomerXml(data.ncCustomers),
    nNCOdrCcfDdscXml: nNCOdrCcfDdscXml,
    actionPlanXml: actionPlanXml,
    concernXML: concernXml,
    summaryOftheWeeksDataXml: buildSummaryXml(
      data.oneWeekODRs.data,
      data.oneWeekAtRiskOdrs.data,
      data.thisWeekNewCcfs.data,
      data.ncCustomers.data,
      data.thisWeekTradeDDC.data,
      data.thisWeekCommercialDDC.data,
    )
  };
  return param;
};

const exportMsWork = async data => {
  const param = await buildParamOfAttachment(data);
  previewDocument('/files/Template.docx', param);
};

const sentMailWithAttactment = async data => {
  const param = await buildParamOfAttachment(data);
  const blob = await getBlob('/files/Template.docx', param);
  const formData = new FormData();
  formData.append('files', blob, 'Summary_Report.docx');

  let participantsArray = data?.participants?.split(',');
  if (!participantsArray || participantsArray.length == 0) {
    swalToastError('Participants persons are empty');
    return;
  }

  participantsArray.forEach(item => {
    formData.append('toByIds', item);
  });

  formData.append('fromByIds', 2);

  const bodyRes = await fetch('/files/CustomerFocusMailTemplate.html');
  if (bodyRes.ok) {
    let bodyText = await bodyRes.text();
    const formattedDate = moment().format('DD/MM/YYYY');
    const currentYear = moment().format('YYYY');

    bodyText = bodyText
      .replace('{{current_date}}', formattedDate)
      .replace('{{current_year}}', currentYear);

    formData.append('Body', bodyText);
  } else {
    console.error('Failed to fetch the HTML template.');
    return;
  }

  formData.append(
    'Subject',
    'Customer Focus & Service Meeting ' +
      moment(new Date()).format('DD/MM/YYYY')
  );

  const apiResponse = await fetch(`${EVA_URL}/emails/send-with-attachments`, {
    method: 'POST',
    body: formData,
    credentials: 'include'
  });

  if (!apiResponse.ok) {
    throw new Error('Failed to send document to API');
  }

  swalToastSuccess("Document sent successfully");
};

const buildAgedODR = (data, faultSource, atRiskData, sdcAgedODRs) => {
  const agedODRSheaders = [
    { headerText: 'Department', value: 'department' },
    { headerText: 'At Risk ODRS', value: 'atRisk' },
    { headerText: 'Supplier Date Changes', value: 'supplierDateChanges' },
    { headerText: 'Aged ODRS', value: 'agedOdrs' },
    { headerText: 'Dept Head Comments', value: 'comments' }
  ];
  const agedODRSRows = faultSource.map(x => {
    let agedOdr = data.filter(a => a.faultSource == x.id).length;
    let atRiskOdr = atRiskData.filter(a => a.faultSource == x.id).length;
    let sdc = sdcAgedODRs.filter(a => a.faultSource == x.id).length;
    return {
      department: x.name,
      atRisk: atRiskOdr,
      supplierDateChanges: sdc,
      agedOdrs: agedOdr,
      comments: ''
    };
  });

  agedODRSRows.push({
    department: 'Total',
    atRisk: agedODRSRows.reduce((total, item) => total + item.atRisk, 0),
    supplierDateChanges: agedODRSRows.reduce(
      (total, item) => total + item.supplierDateChanges,
      0
    ),
    agedOdrs: agedODRSRows.reduce((total, item) => total + item.agedOdrs, 0),
    comments: ''
  });

  return buildTableXml(agedODRSheaders, agedODRSRows);
};

const buildNewODR = (data, faultSource, atRiskData, sdcNewODRs) => {
  const newODRSheaders = [
    { headerText: 'Department', value: 'department' },
    { headerText: 'At Risk ODRS', value: 'atRisk' },
    { headerText: 'Supplier Date Changes', value: 'supplierDateChanges' },
    { headerText: 'New ODRS', value: 'newODRS' },
    { headerText: 'Dept Head Comments', value: 'comments' }
  ];
  const newODRSRows = faultSource.map(x => {
    let atRiskOdr = atRiskData.filter(a => a.faultSource == x.id).length;
    let count = data.filter(a => a.faultSource == x.id).length;
    let sdcCount = sdcNewODRs.filter(a => a.faultSource == x.id).length;
    return {
      department: x.name,
      atRisk: atRiskOdr,
      supplierDateChanges: sdcCount,
      newODRS: count,
      comments: ''
    };
  });

  newODRSRows.push({
    department: 'Total',
    atRisk: newODRSRows.reduce((total, item) => total + item.atRisk, 0),
    supplierDateChanges: newODRSRows.reduce(
      (total, item) => total + item.supplierDateChanges,
      0
    ),
    newODRS: newODRSRows.reduce((total, item) => total + item.newODRS, 0),
    comments: ''
  });
  return buildTableXml(newODRSheaders, newODRSRows);
};

const buildNNCCustomerXml = ncCustomers => {
  let docXml = '<w:body>';

  ncCustomers.data.forEach(entry => {
    const customerList = entry.customerNames
      .map(customer => escapeXml(customer))
      .join(', ');

    const coloredCustomer = `<w:r><w:rPr><w:color w:val="FF0000"/></w:rPr><w:t>${customerList}</w:t></w:r>`;
    docXml += `<w:p><w:r><w:t>${entry.dayOfWeek} – </w:t></w:r><w:r>${coloredCustomer}</w:r></w:p>`;
    docXml += '<w:p></w:p>';
  });

  docXml += '</w:body>';
  return docXml;
};

const buildSummaryXml = (
  oneWeekODRs,
  oneWeekAtRiskOdrs,
  thisWeekNewCcfs,
  ncCustomers, 
  thisWeekTradeDDC,
  thisWeekCommercialDDC,
) => {
  const summaryOftheWeeksHeaders = [
    { headerText: 'QTY', value: 'qty' },
    { headerText: 'Monday', value: 'monday' },
    { headerText: 'Tuesday', value: 'tuesday' },
    { headerText: 'Wednesday', value: 'wednesday' },
    { headerText: 'Thursday', value: 'thursday' },
    { headerText: 'Friday', value: 'friday' }
  ];

  console.log(ncCustomers.filter(x => x.dayOfWeek == 'Friday'))
  const odrData = addDayField(oneWeekODRs);
  const atRiskData = addDayField(oneWeekAtRiskOdrs);
  const newCCfData = addDayField(thisWeekNewCcfs);
  const tradeDDCData = addDayField(thisWeekTradeDDC);
  const commercialDDCData = addDayField(thisWeekCommercialDDC);
  const summaryOftheWeekRows = [
    {
      qty: 'CCFS',
      monday: newCCfData.filter(x => x.day == 'Monday').length,
      tuesday: newCCfData.filter(x => x.day == 'Tuesday').length,
      wednesday: newCCfData.filter(x => x.day == 'Wednesday').length,
      thursday: newCCfData.filter(x => x.day == 'Thursday').length,
      friday: newCCfData.filter(x => x.day == 'Friday').length
    },
    {
      qty: 'ODRS - Aged',
      monday: odrData.filter(x => x.day == 'Monday' && x.isNew == false).length,
      tuesday: odrData.filter(x => x.day == 'Tuesday' && x.isNew == false).length,
      wednesday: odrData.filter(x => x.day == 'Wednesday' && x.isNew == false).length,
      thursday: odrData.filter(x => x.day == 'Thursday' && x.isNew == false).length,
      friday: odrData.filter(x => x.day == 'Friday' && x.isNew == false).length
    },
    {
      qty: 'ODRS – New',
      monday: odrData.filter(x => x.day == 'Monday' && x.isNew == true).length,
      tuesday: odrData.filter(x => x.day == 'Tuesday' && x.isNew == true).length,
      wednesday: odrData.filter(x => x.day == 'Wednesday' && x.isNew == true).length,
      thursday: odrData.filter(x => x.day == 'Thursday' && x.isNew == true).length,
      friday: odrData.filter(x => x.day == 'Friday' && x.isNew == true).length,
    },
    {
      qty: 'DDCS – Trade',
      monday: tradeDDCData.filter(x => x.day == 'Monday').length,
      tuesday: tradeDDCData.filter(x => x.day == 'Tuesday').length,
      wednesday: tradeDDCData.filter(x => x.day == 'Wednesday').length,
      thursday: tradeDDCData.filter(x => x.day == 'Thursday').length,
      friday: tradeDDCData.filter(x => x.day == 'Friday').length,
    },
    {
      qty: 'DDCS – Commercial (includes scheduled jobs)',
      monday: commercialDDCData.filter(x => x.day == 'Monday').length,
      tuesday: commercialDDCData.filter(x => x.day == 'Tuesday').length,
      wednesday: commercialDDCData.filter(x => x.day == 'Wednesday').length,
      thursday: commercialDDCData.filter(x => x.day == 'Thursday').length,
      friday: commercialDDCData.filter(x => x.day == 'Friday').length,
    },
    {
      qty: 'AT RISK ODRS',
      monday: atRiskData.filter(x => x.day == 'Monday').length,
      tuesday: atRiskData.filter(x => x.day == 'Tuesday').length,
      wednesday: atRiskData.filter(x => x.day == 'Wednesday').length,
      thursday: atRiskData.filter(x => x.day == 'Thursday').length,
      friday: atRiskData.filter(x => x.day == 'Friday').length
    },
    {
      qty: 'N&C Customers',
      monday: ncCustomers.find(x => x.dayOfWeek == 'Monday')?.customerNames?.length ?? 0,
      tuesday: ncCustomers.find(x => x.dayOfWeek == 'Tuesday')?.customerNames?.length ?? 0,
      wednesday: ncCustomers.find(x => x.dayOfWeek == 'Wednesday')?.customerNames?.length ?? 0,
      thursday: ncCustomers.find(x => x.dayOfWeek == 'Thursday')?.customerNames?.length ?? 0,
      friday: ncCustomers.find(x => x.dayOfWeek == 'Friday')?.customerNames?.length ?? 0
    }
  ];

  return buildTableXml(summaryOftheWeeksHeaders, summaryOftheWeekRows);
};

const parseHtmlToText = htmlString => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  return doc.body.textContent || '';
};

const addDayField = data => {
  return data.map(record => {
    const date = new Date(record.created);
    // Lấy ngày trong tuần từ đối tượng Date
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
    return {
      ...record,
      day: dayOfWeek
    };
  });
};
export { exportMsWork, sentMailWithAttactment };
